.plt-desktop, .plt-mobileweb {

  #versioninfo, #connection{
    font-size: calc(1.2 * var(--faktor)) !important;
  }

  #versioninfo{
    display: none;
  }

  @media (min-height: 850px) and (max-height: 900px) {
    body{
      zoom: 0.94;
    }
  }

  @media (min-height: 800px) and (max-height: 849px) {
    body{
      zoom: 0.88;
    }
  }

  @media (min-height: 750px) and (max-height: 799px) {
    body{
      zoom: 0.83;
    }
  }

  @media (min-height: 700px) and (max-height: 749px) {
    body{
      zoom: 0.77;
    }
  }

  @media (min-height: 650px) and (max-height: 699px) {
    body{
      zoom: 0.72;
    }
  }

  @media (min-height: 600px) and (max-height: 649px) {
    body{
      zoom: 0.66;
    }
  }

  @media (min-height: 550px) and (max-height: 599px) {
    body{
      zoom: 0.61;
    }
  }

  @media (min-height: 500px) and (max-height: 549px) {
    body{
      zoom: 0.55;
    }
  }

  @media (min-height: 450px) and (max-height: 499px) {
    body{
      zoom: 0.50;
    }
  }

  
  .resolution{    

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,0.5);
    backdrop-filter: blur(15px);
    z-index: 999999;

    .text{
      color: #000;
      padding: 15px;
      font-family: "Lato-Regular";
      font-Size: calc(3.2 * var(--faktor)) !important;
      line-height: calc(4.5 * var(--faktor)) !important;
      max-width: 350px;
      background: rgba(255,255,255,0.8);
      border-radius: 20px;
      border: 5px solid red;
      hyphens: auto;
    }

    @media (min-height: 450px) {
      display: none;
    }

    
  }
  

  

  @media (min-width: 790px) {

    --dwidth: 650px;
    --dheight: 900px;
    --faktor: 6.5px;

    ion-app {
      background: url(/assets/images/lakost_hg_web.png) no-repeat center center;
    }
  }

  @media (max-width: 789px) {

    --dwidth: 100vw;
    --dheight: calc( 100vw * 1.3846);
    --faktor: 1vw; 
    //--faktor: calc((100vw - 120px) / 100); 

    ion-app { 
      background: #000;   
    }

  }

  ion-header {
    //height: 125px;
    height: min(calc(125 * 100vw/650), 125px) !important;

    .shadow {
      height: min(calc(45 * 100vw/650), 45px) !important;
      line-height: min(calc(45 * 100vw/650), 45px) !important;
      padding-top: 0;

      .s_punkte, .s_zeit{
        font-size: calc(2.25 * var(--faktor)) !important;
        width: min(calc(120 * 100vw/650), 120px) !important;
      }

      .title {
        font-size: calc(2 * var(--faktor)) !important;
      }
    }

    ion-toolbar {

      min-height: min(calc(80 * 100vw/650), 80px) !important;
      height: min(calc(80 * 100vw/650), 80px) !important;

      --min-height: min(calc(80 * 100vw/650), 80px) !important;

      ion-title{
        line-height: min(calc(80 * 100vw/650), 80px) !important;
        height: min(calc(80 * 100vw/650), 80px) !important;
        font-Size: calc(4.6 * var(--faktor)) !important;
      }

      .d_title {
        padding-left: calc(10 * var(--faktor)) !important;
        font-Size: min(calc(3 * var(--faktor)), 16px) !important;

        --min-height: min(calc(80 * 100vw/650), 80px) !important;
        line-height: min(calc(80 * 100vw/650), 80px) !important;
        height: min(calc(80 * 100vw/650), 80px) !important;

        .avatar_toolbar{
          height: min(calc(50 * 100vw/650), 50px) !important;
          border-radius: min(calc(60 * 100vw/650), 60px) !important;
          top: min(calc(15 * 100vw/650), 15px) !important;
          
        }

        
      }

      .trans {
        padding-right: calc(10 * var(--faktor)) !important;
        font-Size: min(calc(6 * var(--faktor)), 32px) !important;

        min-height: min(calc(80 * 100vw/650), 80px);
        height: min(calc(80 * 100vw/650), 80px);
        line-height: min(calc(80 * 100vw/650), 80px);
      }
    }
  }

  app-home {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;


    #lblSubtext {
      left: calc(10 * var(--faktor)) !important;
      top: calc(85 * var(--faktor)) !important;
      font-Size: calc(3.2 * var(--faktor)) !important;
      width: calc(44 * var(--faktor)) !important;
    }


    #btnZurAnmeldung {
      top: calc(115 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      width: calc(25 * var(--faktor)) !important;
      height: calc(5 * var(--faktor)) !important;
      font-Size: calc(2 * var(--faktor)) !important;
      border-radius: calc(1.25 * var(--faktor)) !important;
    }


    #btnKeineVerbindung {
      top: calc(115 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      width: calc(25 * var(--faktor)) !important;
      height: calc(5 * var(--faktor)) !important;
      font-Size: calc(2 * var(--faktor)) !important;
      border-radius: calc(1.25 * var(--faktor)) !important;
    }


    #lblAppName {
      font-size: calc(6 * var(--faktor)) !important;
      width: 100%;
      top: calc(11.5 * var(--faktor)) !important;
      line-height: calc(10 * var(--faktor)) !important;
    }

  }

  app-anmeldung {
    
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #einleitungstext {
      font-size: calc(3 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      top: calc(9 * var(--faktor)) !important;
    }

    #wrapKamera {
      top: calc(32 * var(--faktor)) !important;
      width: calc(35 * var(--faktor)) !important;
      height: calc(35 * var(--faktor)) !important;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    #imgDefault {
      width: calc(35 * var(--faktor)) !important;
      height: calc(35 * var(--faktor)) !important;
      border-radius: calc(17.5 * var(--faktor)) !important;
    }

    #imgKamera {
      height: calc(10 * var(--faktor)) !important;
      width: calc(10 * var(--faktor)) !important;
    }

    #inputName {
      top: calc(88 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      width: auto !important;

      height: calc(6 * var(--faktor)) !important;

      font-size: calc(3 * var(--faktor)) !important;

      border-radius: calc(0.5 * var(--faktor)) !important;
      --padding-start: calc(1.5 * var(--faktor)) !important;
    }

    #btnZumQuiz {
      bottom: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      width: calc(22 * var(--faktor)) !important;
      height: calc(6 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;

    }

    #wrapGeschlecht {
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      top: calc(79 * var(--faktor)) !important;
    }



    #lblMaennlich {
      font-size: calc(3 * var(--faktor)) !important;
      left: calc(5 * var(--faktor)) !important;
    }

    #lblWeiblich {
      font-size: calc(3 * var(--faktor)) !important;
    }

    #lblDivers {
      font-size: calc(3 * var(--faktor)) !important;
    }

    .radioButton {
      width: calc(3 * var(--faktor)) !important;
      height: calc(3 * var(--faktor)) !important;

      border-radius: calc(3 * var(--faktor)) !important;
      top: 4px;

      &.active:after {

        width: calc(2.2 * var(--faktor)) !important;
        height: calc(2.2 * var(--faktor)) !important;
        border-radius: calc(2.2 * var(--faktor)) !important;
        top: calc(0.4 * var(--faktor)) !important;
        left: calc(0.4 * var(--faktor)) !important;
      }
    }
  }

  app-warten {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    .ai {
      width: calc(20 * var(--faktor)) !important;
      height: calc(20 * var(--faktor)) !important;
      top: calc(42 * var(--faktor)) !important;
      left: calc(40 * var(--faktor)) !important;
    }

    #lblBitteWarten{
      top: calc(17 * var(--faktor)) !important;
      font-size: calc(5 * var(--faktor)) !important;
      left: calc(1 * var(--faktor)) !important;
      right: calc(1 * var(--faktor)) !important;
    }

    #lblUnten {
      font-size: calc(2.7 * var(--faktor)) !important;
      bottom: calc(12.3 * var(--faktor)) !important;
      right: calc(12.3 * var(--faktor)) !important;
      left: auto !important;
    }

  }

  app-station1-einleitung,
  app-station2-einleitung,
  app-station3-einleitung,
  app-station4-einleitung,
  app-station4-alk-einleitung,
  app-station5-einleitung {

    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #einleitungstext {
      font-size: calc(3 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      top: calc(5 * var(--faktor)) !important;
    }

    #btnLosGehtsSt1,
    #btnLosGehtsSt2,
    #btnLosGehtsSt3,
    #btnLosGehtsSt4,
    #btnLosGehtsSt4a,
    #btnLosGehtsSt5 {

      bottom: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      width: calc(30 * var(--faktor)) !important;
      height: calc(6 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;
    }

  }

  app-station1-quiz {

    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #lblFrageVon {
      top: calc(3 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(3.5 * var(--faktor)) !important;
    }

    #wrapAll {
      top: calc(9 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      height: calc(93 * var(--faktor)) !important;
    }

    #wrapFrage {
      border-radius: calc(1.5 * var(--faktor)) !important;
      padding: calc(1.5 * var(--faktor)) !important;
      margin-bottom: calc(6 * var(--faktor)) !important;
    }


    #lblFrage {
      font-size: calc(2.6 * var(--faktor)) !important;
    }

    #imgDreieck {
      bottom: calc(-2.8 * var(--faktor)) !important;
      width: calc(6 * var(--faktor)) !important;
    }

    #wrapHinweistext {
      margin-top: calc(3 * var(--faktor)) !important;
    }

    #imgRichtigFalsch {
      width: calc(21 * var(--faktor)) !important;
    }

    #scrollText {
      max-height: calc(27 * var(--faktor)) !important;
      padding: calc(1.5 * var(--faktor)) !important;
      overflow: auto;
    }

    #lblHinweistext {
      font-size: calc(2.6 * var(--faktor)) !important;
      line-height: calc(4.5 * var(--faktor)) !important;
      display: block;
    }

    #btnWeiter {
      --padding-start: calc(4 * var(--faktor)) !important;
      --padding-end: calc(4 * var(--faktor)) !important;
      width: calc(35 * var(--faktor)) !important;
      height: calc(6 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
      bottom: calc(5 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;
    }

    .antwort_wrap {
      margin-top: calc(2 * var(--faktor)) !important;
      min-height: calc(6 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;

      .fragenummer {
        font-size: calc(2.4 * var(--faktor)) !important;
        width: calc(6 * var(--faktor)) !important;
        line-height: calc(6 * var(--faktor)) !important;
        border-top-left-radius: calc(1.5 * var(--faktor)) !important;
        border-bottom-left-radius: calc(1.5 * var(--faktor)) !important;
      }

      .antworttext {
        line-height: calc(3 * var(--faktor)) !important;
        font-size: calc(2.6 * var(--faktor)) !important;
        padding: 0 calc(1.5 * var(--faktor)) !important;
      }
    }
  }

  app-station1-ergebnis,
  app-station2-ergebnis,
  app-station3-ergebnis,
  app-station4-ergebnis,
  app-station4-alk-ergebnis,
  app-station5-ergebnis {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #lblDeinePunktzahl {
      top: calc(20 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(4 * var(--faktor)) !important;
    }

    #imgHalbkreis {
      top: calc(36 * var(--faktor)) !important;
      width: calc(35 * var(--faktor)) !important;
    }

    #lblPunkte {
      top: calc(46 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(10 * var(--faktor)) !important;
    }

    #lblGleichWeiter {
      bottom: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(2.5 * var(--faktor)) !important;
    }

    #btnWeiter {
      --padding-start: calc(4 * var(--faktor)) !important;
      --padding-end: calc(4 * var(--faktor)) !important;
      height: calc(6 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
      bottom: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;
    }
  }

  app-station2-quiz {

    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #imgDocking {
      top: calc(10 * var(--faktor)) !important;
      width: calc(13.5 * var(--faktor)) !important;
    }

    .aussage {
      height: calc(4.25 * var(--faktor)) !important;
      border-radius: calc(2.125 * var(--faktor)) !important;
      font-size: calc(1.5 * var(--faktor)) !important;
      line-height: calc(4.25 * var(--faktor)) !important;
      padding: 0 calc(4 * var(--faktor)) !important;
    }

    #btnAuswerten,
    #btnWeiter {
      --padding-start: calc(4 * var(--faktor)) !important;
      --padding-end: calc(4 * var(--faktor)) !important;
      height: calc(6 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
      bottom: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;
    }
  }

  app-station3-quiz {

    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    .snapinfield {
      width: calc(38 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      height: calc(6.1 * var(--faktor)) !important;

      .nummer {
        width: calc(6.8 * var(--faktor)) !important;
        height: calc(6.1 * var(--faktor)) !important;
        font-size: calc(2.5 * var(--faktor)) !important;
        line-height: calc(6.1 * var(--faktor)) !important;
      }

    }

    .aussage {
      width: calc(31 * var(--faktor)) !important;
      height: calc(6.1 * var(--faktor)) !important;
      line-height: calc(6.1 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
    }

    #btnAuswerten,
    #btnWeiter {
      --padding-start: calc(4 * var(--faktor)) !important;
      --padding-end: calc(4 * var(--faktor)) !important;
      height: calc(6 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
      bottom: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;
    }
  }

  app-station4-quiz {

    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;


    #btnAuswerten,
    #btnWeiter {
      --padding-start: calc(4 * var(--faktor)) !important;
      --padding-end: calc(4 * var(--faktor)) !important;
      height: calc(6 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
      bottom: calc(5 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;
    }

    #wrapHinweistext {
      top: calc(69 * var(--faktor)) !important;
      left: 10% !important;
      right: 10% !important;
    }

    #imgRichtigFalsch {
      width: 21% !important;
    }

    #scrollText {
      max-height: calc(24 * var(--faktor)) !important;
      padding: calc(2.5 * var(--faktor)) !important;
    }

    #lblHinweistext {
      font-size: calc(2.2 * var(--faktor)) !important;
      line-height: calc(4.5 * var(--faktor)) !important;
      display: block;
    }

    #imgStoff {
      left: 10% !important;
      top: calc(3 * var(--faktor)) !important;
      width: 38% !important;
      border: calc(2 * var(--faktor)) solid #fff;
    }

    #wrapNamen {
      left: 53% !important;
      top: calc(3 * var(--faktor)) !important;
      width: 37% !important;
    }

    #wrapPositiv {
      left:10% !important;
      top: calc(41 * var(--faktor)) !important;
      width: 38% !important;
    }

    #wrapNegativ {
      left: 53% !important;
      top: calc(41 * var(--faktor)) !important;
      width: 37% !important;
    }

    .antwort {
      margin-bottom: calc(2.5 * var(--faktor)) !important;
      height: calc(6 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;

      .buchstabe {
        font-size: calc(2 * var(--faktor)) !important;
        width: calc(6 * var(--faktor)) !important;
        height: calc(6 * var(--faktor)) !important;
        line-height: calc(6 * var(--faktor)) !important;
        border-top-left-radius: calc(1.5 * var(--faktor)) !important;
        border-bottom-left-radius: calc(1.5 * var(--faktor)) !important;
      }

      .text {
        font-size: calc(2 * var(--faktor)) !important;
        padding: 0 calc(2 * var(--faktor)) !important;
        width: 100% !important;
      }

    }


  }

  app-einzelauswertung {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #lblDeinePunktzahl {
      top: calc(20 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(4 * var(--faktor)) !important;
    }

    #imgHalbkreis {
      top: calc(36 * var(--faktor)) !important;
      width: calc(35 * var(--faktor)) !important;
    }

    #lblPunkte {
      top: calc(46 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(10 * var(--faktor)) !important;
    }


    .station {
      font-size: calc(2.5 * var(--faktor)) !important;
      left: calc(32 * var(--faktor)) !important;
    }

    .punkte {
      font-size: calc(3.5 * var(--faktor)) !important;
      left: calc(52.5 * var(--faktor)) !important;
    }

    #lblStation1 {
      top: calc(81 * var(--faktor)) !important;
    }

    #lblStation2 {
      top: calc(90 * var(--faktor)) !important;
    }

    #lblStation3 {
      top: calc(99 * var(--faktor)) !important;
    }

    #lblStation4 {
      top: calc(108 * var(--faktor)) !important;
    }

    #lblStation5 {
      top: calc(117 * var(--faktor)) !important;
    }

    #lblPunkte1 {
      top: calc(82 * var(--faktor)) !important;
    }

    #lblPunkte2 {
      top: calc(91 * var(--faktor)) !important;
    }

    #lblPunkte3 {
      top: calc(100 * var(--faktor)) !important;
    }

    #lblPunkte4 {
      top: calc(109 * var(--faktor)) !important;
    }

    #lblPunkte5 {
      top: calc(118 * var(--faktor)) !important;
    }

  }

  app-gesamtauswertung {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #lblDeinePunktzahl {
      top: calc(20 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(4 * var(--faktor)) !important;
    }

    #lblSchule {
      left: calc(10 * var(--faktor)) !important;
      top: calc(5 * var(--faktor)) !important;
      font-size: calc(2.75 * var(--faktor)) !important;
    }

    #lblPunkte {
      top: calc(46 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(11.5 * var(--faktor)) !important;
    }

    #wrapListe {
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      top: calc(71 * var(--faktor)) !important;

      .ergebnis {
        height: calc(7.5 * var(--faktor)) !important;
        line-height: calc(7.5 * var(--faktor)) !important;

        &.nut5 {
          margin-top: calc(3 * var(--faktor)) !important;
        }

        .platz {
          font-size: calc(2.5 * var(--faktor)) !important;
          width: calc(12 * var(--faktor)) !important;

          ion-img {
            height: calc(4 * var(--faktor)) !important;
          }
        }

        .bild {
          margin-left: calc(1 * var(--faktor)) !important;
          margin-right: calc(3 * var(--faktor)) !important;
          height: calc(6 * var(--faktor)) !important;
          width: calc(6 * var(--faktor)) !important;
          border-radius: calc(6 * var(--faktor)) !important;
        }

        .name {
          font-size: calc(2.75 * var(--faktor)) !important;
          margin-left: calc(2 * var(--faktor)) !important;
          width: calc(40 * var(--faktor)) !important;
        }

        .punkte {
          font-size: calc(2.75 * var(--faktor)) !important;
          margin-left: calc(2 * var(--faktor)) !important;
          width: calc(10 * var(--faktor)) !important;
        }
      }
    }
  }

  app-wartenjoint {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #imgJoint {
      width: calc(36 * var(--faktor)) !important;
      height: calc(36 * var(--faktor)) !important;
      top: calc(36 * var(--faktor)) !important;
      left: calc(32 * var(--faktor)) !important;
    }

    #lblUnten {
      font-size: calc(2.5 * var(--faktor)) !important;
      bottom: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
    }

    #lblJointIstHeiss {
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(6.5 * var(--faktor)) !important;
      top: calc(12 * var(--faktor)) !important;
    }
  }

  app-jointistheiss {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #wrapJokerPunkte {
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      top: calc(9 * var(--faktor)) !important;
    }

    #imgJokerEinzel {
      width: calc(7.5 * var(--faktor)) !important;
      height: calc(7.5 * var(--faktor)) !important;
    }

    #imgJokerGruppe {
      top: calc(10 * var(--faktor)) !important;
      width: calc(7.5 * var(--faktor)) !important;
      height: calc(7.5 * var(--faktor)) !important;
    }

    #imgJoker5050 {
      top: calc(20 * var(--faktor)) !important;
      width: calc(7.5 * var(--faktor)) !important;
      height: calc(7.5 * var(--faktor)) !important;
    }


    .wrapSpiel {
      height: calc(4 * var(--faktor)) !important;
      width: calc(20 * var(--faktor)) !important;
      border-radius: calc(2 * var(--faktor)) !important;
    }



    .wrapSpielLinkeSpalte {
      left: calc(31.4 * var(--faktor)) !important;
    }


    #wrapSpiel2 {
      top: calc(6 * var(--faktor)) !important;
    }

    #wrapSpiel3 {
      top: calc(11.875 * var(--faktor)) !important;
    }

    #wrapSpiel4 {
      top: calc(17.81 * var(--faktor)) !important;
    }

    #wrapSpiel5 {
      top: calc(23.75 * var(--faktor)) !important;
    }

    #wrapSpiel7 {
      top: calc(6 * var(--faktor)) !important;
    }

    #wrapSpiel8 {
      top: calc(11.875 * var(--faktor)) !important;
    }

    #wrapSpiel9 {
      top: calc(17.81 * var(--faktor)) !important;
    }

    #wrapSpiel10 {
      top: calc(23.75 * var(--faktor)) !important;
    }


    .lblSpielNr {
      left: calc(0.375 * var(--faktor)) !important;
      font-size: calc(1.5 * var(--faktor)) !important;
      width: calc(4.18 * var(--faktor)) !important;
      line-height: calc(4 * var(--faktor)) !important;
    }

    .imgPunkt {
      width: calc(2 * var(--faktor)) !important;
      height: calc(2 * var(--faktor)) !important;
      top: calc(1 * var(--faktor)) !important;
      left: calc(4.7 * var(--faktor)) !important;
    }

    .lblSpielPunkte {
      left: calc(5.125 * var(--faktor)) !important;
      width: calc(14.6875 * var(--faktor)) !important;
      line-height: calc(4 * var(--faktor)) !important;
      font-size: calc(2.5 * var(--faktor)) !important;
    }

    #wrapFragen {
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      top: calc(43.25 * var(--faktor)) !important;
    }

    #lblFrageVon {
      font-size: calc(3.5 * var(--faktor)) !important;
    }

    #lblPunkte {
      font-size: calc(3.5 * var(--faktor)) !important;
    }

    #wrapFrage {
      top: calc(10 * var(--faktor)) !important;
    }

    #wrapFrageBg {
      border-radius: calc(1.25 * var(--faktor)) !important;
    }

    #lblFrage {
      font-size: calc(2.5 * var(--faktor)) !important;
      padding: calc(2 * var(--faktor)) !important;
    }

    #imgDreieck {
      left: calc(4.375 * var(--faktor)) !important;
      bottom: calc(-1.9 * var(--faktor)) !important;
      width: calc(4 * var(--faktor)) !important;
    }

    .wrapAntwort {
      height: calc(6 * var(--faktor)) !important;
      border-radius: calc(1.25 * var(--faktor)) !important;
    }

    #wrapAntwort1 {
      top: calc(23.5 * var(--faktor)) !important;
    }

    #wrapAntwort2 {
      top: calc(33.5 * var(--faktor)) !important;
    }

    #wrapAntwort3 {
      top: calc(43.5 * var(--faktor)) !important;
    }

    #wrapAntwort4 {
      top: calc(53.5 * var(--faktor)) !important;
    }

    .wrapKastenLinks {
      width: calc(6 * var(--faktor)) !important;
      height: calc(6 * var(--faktor)) !important;
    }

    .lblBuchstabe {
      font-size: calc(2.5 * var(--faktor)) !important;
    }

    .wrapKastenLinks {
      border-top-left-radius: calc(1.25 * var(--faktor)) !important;
      border-bottom-left-radius: calc(1.25 * var(--faktor)) !important;
    }

    .lblFrage {
      left: calc(6 * var(--faktor)) !important;
      font-size: calc(2.3 * var(--faktor)) !important;
    }

    #imgKoordinaten {
      left: calc(26 * var(--faktor)) !important;
      top: calc(4 * var(--faktor)) !important;
      width: calc(45 * var(--faktor)) !important;
    }

    #lblYAchseOben {
      font-size: calc(2.75 * var(--faktor)) !important;
      top: calc(4.5 * var(--faktor)) !important;
      left: calc(19.75 * var(--faktor)) !important;
      width: calc(5.8125 * var(--faktor)) !important;
    }

    #lblYAchseUnten {
      font-size: calc(2.75 * var(--faktor)) !important;
      top: calc(19.125 * var(--faktor)) !important;
      left: calc(19.75 * var(--faktor)) !important;
      width: calc(5.8125 * var(--faktor)) !important;
    }

    #lblXA {
      font-size: calc(2.5 * var(--faktor)) !important;
      top: calc(36.75 * var(--faktor)) !important;
      left: calc(30.5625 * var(--faktor)) !important;
      width: calc(6.0625 * var(--faktor)) !important;
      height: calc(6.5625 * var(--faktor)) !important;
    }

    #lblXB {
      font-size: calc(2.5 * var(--faktor)) !important;
      top: calc(36.75 * var(--faktor)) !important;
      left: calc(41.4375 * var(--faktor)) !important;
      width: calc(6.0625 * var(--faktor)) !important;
      height: calc(6.5625 * var(--faktor)) !important;
    }

    #lblXC {
      font-size: calc(2.5 * var(--faktor)) !important;
      top: calc(36.75 * var(--faktor)) !important;
      left: calc(52.3125 * var(--faktor)) !important;
      width: calc(6.0625 * var(--faktor)) !important;
      height: calc(6.5625 * var(--faktor)) !important;
    }

    #lblXD {
      font-size: calc(2.5 * var(--faktor)) !important;
      top: calc(36.75 * var(--faktor)) !important;
      left: calc(63.1875 * var(--faktor)) !important;
      width: calc(6.0625 * var(--faktor)) !important;
      height: calc(6.5625 * var(--faktor)) !important;
    }

    .balken {
      width: calc(3.25 * var(--faktor)) !important;
      bottom: 70%;
    }

    #balken1 {
      left: calc(31.9375 * var(--faktor)) !important;
    }

    #balken2 {
      left: calc(42.875 * var(--faktor)) !important;
    }

    #balken3 {
      left: calc(53.75 * var(--faktor)) !important;
    }

    #balken4 {
      left: calc(64.6875 * var(--faktor)) !important;
    }

    .lblBalken {
      font-size: calc(2.75 * var(--faktor)) !important;
      width: calc(6.375 * var(--faktor)) !important;
      height: calc(4.25 * var(--faktor)) !important;
    }

    #lblBalken1 {
      left: calc(30.3125 * var(--faktor)) !important;
    }

    #lblBalken2 {
      left: calc(41.375 * var(--faktor)) !important;
    }

    #lblBalken3 {
      left: calc(52.5 * var(--faktor)) !important;
    }

    #lblBalken4 {
      left: calc(63.5625 * var(--faktor)) !important;
    }

    #imgPublikumJokerH1 {
      left: calc(10 * var(--faktor)) !important;
      top: calc(9.4375 * var(--faktor)) !important;
      width: calc(7.375 * var(--faktor)) !important;
      height: calc(7.375 * var(--faktor)) !important;
    }

    #lblPublikumJokerH1 {
      left: calc(20.25 * var(--faktor)) !important;
      top: calc(8 * var(--faktor)) !important;
      font-size: calc(4 * var(--faktor)) !important;
    }

    #lblPublikumJokerJetztAntworten {
      top: calc(28.625 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(4 * var(--faktor)) !important;
    }

    #publikumCounter {
      height: calc(16.5 * var(--faktor)) !important;
      width: calc(16.5 * var(--faktor)) !important;
      right: calc(28 * var(--faktor)) !important;
      top: calc(12.5 * var(--faktor)) !important;
    }
  }

  app-jointauswertung {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #lblJointIstHeiss {
      font-size: calc(6 * var(--faktor)) !important;
      top: calc(13.875 * var(--faktor)) !important;
    }

    #lblPunkte {
      top: calc(43.3125 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(11.5 * var(--faktor)) !important;
    }

    .wrapSpiel {
      height: calc(4 * var(--faktor)) !important;
      width: calc(20.3125 * var(--faktor)) !important;
      border-radius: calc(4 * var(--faktor)) !important;
    }

    #wrapPunkte {
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      top: calc(72.25 * var(--faktor)) !important;
    }

    .wrapSpielLinkeSpalte {
      left: calc(17.3125 * var(--faktor)) !important;
    }

    .wrapSpielRechteSpalte {
      left: calc(43.25 * var(--faktor)) !important;
    }

    #wrapSpiel2 {
      top: calc(5.9375 * var(--faktor)) !important;
    }

    #wrapSpiel3 {
      top: calc(11.875 * var(--faktor)) !important;
    }

    #wrapSpiel4 {
      top: calc(17.8125 * var(--faktor)) !important;
    }

    #wrapSpiel5 {
      top: calc(23.75 * var(--faktor)) !important;
    }

    #wrapSpiel7 {
      top: calc(5.9375 * var(--faktor)) !important;
    }

    #wrapSpiel8 {
      top: calc(11.875 * var(--faktor)) !important;
    }

    #wrapSpiel9 {
      top: calc(17.8125 * var(--faktor)) !important;
    }

    #wrapSpiel10 {
      top: calc(23.75 * var(--faktor)) !important;
    }


    .lblSpielNr {
      left: calc(0.375 * var(--faktor)) !important;
      font-size: calc(1.5 * var(--faktor)) !important;
      width: calc(4.1875 * var(--faktor)) !important;
      height: calc(3.875 * var(--faktor)) !important;
    }

    .imgPunkt {
      width: calc(1 * var(--faktor)) !important;
      height: calc(1 * var(--faktor)) !important;
      top: calc(1.5 * var(--faktor)) !important;
      left: calc(4.1875 * var(--faktor)) !important;
    }

    .lblSpielPunkte {
      left: calc(5.125 * var(--faktor)) !important;
      width: calc(14.6875 * var(--faktor)) !important;
      line-height: calc(4 * var(--faktor)) !important;
      font-size: calc(2.5 * var(--faktor)) !important;
    }
  }

  app-jointauswertungalles {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #lblGesamtuebersicht {
      font-size: calc(6 * var(--faktor)) !important;
      top: calc(13.875 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
    }

    #lblPunkte {
      top: calc(43.3125 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      font-size: calc(11.5 * var(--faktor)) !important;
    }

    #lblStation {
      font-size: calc(2.5 * var(--faktor)) !important;
      left: calc(23.25 * var(--faktor)) !important;
      top: calc(71.1875 * var(--faktor)) !important;
    }

    #lblJoint {
      font-size: calc(2.5 * var(--faktor)) !important;
      left: calc(23.25 * var(--faktor)) !important;
      top: calc(79.25 * var(--faktor)) !important;
    }

    #lblZusatz {
      font-size: calc(2.5 * var(--faktor)) !important;
      left: calc(23.25 * var(--faktor)) !important;
      top: calc(87.3125 * var(--faktor)) !important;
    }

    #lblPunkteStation {
      font-size: calc(3.5 * var(--faktor)) !important;
      left: calc(55.1875 * var(--faktor)) !important;
      width: calc(17.5 * var(--faktor)) !important;
      top: calc(72.4375 * var(--faktor)) !important;
    }

    #lblPunkteJoint {
      font-size: calc(3.5 * var(--faktor)) !important;
      left: calc(55.1875 * var(--faktor)) !important;
      width: calc(17.5 * var(--faktor)) !important;
      top: calc(80.5 * var(--faktor)) !important;
    }

    #lblPunkteZusatz {
      font-size: calc(3.5 * var(--faktor)) !important;
      left: calc(55.1875 * var(--faktor)) !important;
      width: calc(17.5 * var(--faktor)) !important;
      top: calc(88.5625 * var(--faktor)) !important;
    }

  }

  app-danke {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #lblSubtext {
      left: calc(10 * var(--faktor)) !important;
      top: calc(85 * var(--faktor)) !important;
      font-Size: calc(3.2 * var(--faktor)) !important;
      width: calc(44 * var(--faktor)) !important;
    }


    #btnZurAnmeldung {
      top: calc(115 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      width: calc(25 * var(--faktor)) !important;
      height: calc(5 * var(--faktor)) !important;
      font-Size: calc(2 * var(--faktor)) !important;
      border-radius: calc(1.25 * var(--faktor)) !important;
    }


    #btnKeineVerbindung {
      top: calc(115 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      width: calc(25 * var(--faktor)) !important;
      height: calc(5 * var(--faktor)) !important;
      font-Size: calc(2 * var(--faktor)) !important;
      border-radius: calc(1.25 * var(--faktor)) !important;
    }

    #lblAppName {
      font-size: calc(6 * var(--faktor)) !important;
      top: calc(11.5 * var(--faktor)) !important;
    }

  }

  app-feedback {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #einleitungstext {
      font-size: calc(2.5 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      top: calc(6.25 * var(--faktor)) !important;
      width: calc(40 * var(--faktor)) !important;
    }


    #imgMeinungZaehlt {
      top: calc(4.4375 * var(--faktor)) !important;
      right: calc(8.5625 * var(--faktor)) !important;
      width: calc(40.9375 * var(--faktor)) !important;
    }

    .lblAuswertungErfolgt {
      left: calc(10 * var(--faktor)) !important;
      top: calc(100 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
    }

    #wrapFragenkatalog1 {
      padding-top: calc(42 * var(--faktor)) !important;
    }

    #wrapFragenkatalog2 {
      padding-top: calc(2 * var(--faktor)) !important;
    }

    #lblBemerkungen {
      padding-left: calc(10 * var(--faktor)) !important;
      margin-top: calc(8 * var(--faktor)) !important;
      font-size: calc(2.75 * var(--faktor)) !important;
    }

    #taBemerkungen {
      height: calc(18.75 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;
      margin-top: calc(3 * var(--faktor)) !important;
      font-size: calc(2.75 * var(--faktor)) !important;
      margin-left: calc(10 * var(--faktor)) !important;
      width: calc(80 * var(--faktor)) !important;
      --padding-bottom: calc(1 * var(--faktor)) !important;
      --padding-end: calc(1 * var(--faktor)) !important;
      --padding-start: calc(1 * var(--faktor)) !important;
      --padding-top: calc(1 * var(--faktor)) !important;
    }


    .wrapFrage {
      padding-left: calc(10 * var(--faktor)) !important;
      padding-right: calc(10 * var(--faktor)) !important;
      height: calc(5 * var(--faktor)) !important;
      margin-top: calc(8 * var(--faktor)) !important;
    }

    .lblFrage {
      font-size: calc(2.75 * var(--faktor)) !important;
    }

    .wrapRadioGroup {
      margin-top: calc(-1 * var(--faktor)) !important;
    }

    .radioButton {
      width: calc(5.3125 * var(--faktor)) !important;
      height: calc(5.3125 * var(--faktor)) !important;
      margin-left: calc(1.5 * var(--faktor)) !important;
    }

    .btnWeiter {
      bottom: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      width: calc(19 * var(--faktor)) !important;
      height: calc(6 * var(--faktor)) !important;
      --padding-start: calc(4 * var(--faktor)) !important;
      --padding-end: calc(4 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;
    }

    #imgVielenDank {
      width: calc(36.6875 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      bottom: calc(10 * var(--faktor)) !important;
    }

  }

  app-station4-alk-quiz {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    #zuordnung {
      top: calc(8 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      right: calc(24 * var(--faktor)) !important;
    }

    #zuordnung2x {
      top: calc(7.6 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      width: calc(11.4 * var(--faktor)) !important;
    }

    .spalte {
      top: calc(30 * var(--faktor)) !important;
    }

    #spalte0 .antwort-wrap {
      left: calc(10 * var(--faktor)) !important;
    }

    #spalte1 .antwort-wrap {
      left: calc(23.65 * var(--faktor)) !important;
    }

    #spalte2 .antwort-wrap {
      left: calc(37.3 * var(--faktor)) !important;
    }

    #spalte3 .antwort-wrap {
      left: calc(51 * var(--faktor)) !important;
    }

    #spalte4 .antwort-wrap {
      left: calc(64.6 * var(--faktor)) !important;
    }


    #spalte5 .antwort-wrap {
      right: calc(10 * var(--faktor)) !important;
    }

    .antwort-wrap {
      margin-bottom: calc(6 * var(--faktor)) !important;
      height: min(calc(60 * 100vw/650), 60px) !important;
      width: calc(11.4 * var(--faktor)) !important;
      border-radius: min(calc(10 * 100vw/650), 10px) !important;
      font-size: min(calc(12 * 100vw/650), 12px) !important;

      &.zeile-0 {
        top: 28%;
      }

      &.zeile-1 {
        top: 40%;
      }

      &.zeile-2 {
        top: 52%;
      }

      &.zeile-3 {
        top: 64%;
      }

      &::before {
        top: calc(-3.5 * var(--faktor)) !important;
        width: calc(5 * var(--faktor)) !important;
        height: calc(5 * var(--faktor)) !important;
        border-radius: calc(2.5 * var(--faktor)) !important;
      }
    }

    #btnAuswerten,
    #btnAuswerten2,
    #btnWeiter {
      --padding-start: calc(4 * var(--faktor)) !important;
      --padding-end: calc(4 * var(--faktor)) !important;
      height: calc(6 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
      bottom: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;
    }

    #kokarte_text {
      font-size: calc(2.5 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      top: calc(92 * var(--faktor)) !important;
      line-height: calc(4 * var(--faktor)) !important;
    }

    #verdoppler_text {
      font-size: calc(2.5 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      top: calc(86 * var(--faktor)) !important;
      line-height: calc(4 * var(--faktor)) !important;
    }

    #verdoppler_pfeil {
      top: calc(87 * var(--faktor)) !important;
      right: calc(13 * var(--faktor)) !important;
      width: calc(16 * var(--faktor)) !important;
    }

    .verdoppelt_auswertung_text {
      font-size: calc(2.5 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      top: calc(86 * var(--faktor)) !important;
      line-height: calc(4 * var(--faktor)) !important;
      padding-left: calc(7 * var(--faktor)) !important;

      ion-img {
        width: calc(5 * var(--faktor)) !important;
        top: calc(4 * var(--faktor)) !important;
      }
    }
  }

  app-station5-quiz {
    width: var(--dwidth);
    height: var(--dheight);
    margin: auto;

    .pointer-events-none {
      pointer-events: none;
    }

    #span_reihe {
      left: calc(10 * var(--faktor)) !important;
      top: calc(5 * var(--faktor)) !important;
      font-size: calc(3 * var(--faktor)) !important;

    }

    #videocontainer {
      top: calc(15 * var(--faktor)) !important;
      left: calc(10 * var(--faktor)) !important;
      width: calc(30 * var(--faktor)) !important;
    }

    #btnWeiter {
      height: calc(6 * var(--faktor)) !important;
      font-size: calc(2 * var(--faktor)) !important;
      bottom: calc(10 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;
      border-radius: calc(1.5 * var(--faktor)) !important;
      --padding-start: calc(4 * var(--faktor)) !important;
      --padding-end: calc(4 * var(--faktor)) !important;
    }

    .video {
      width: calc(20.6 * var(--faktor)) !important;
      height: calc(13.7 * var(--faktor)) !important;
      right: calc(10 * var(--faktor)) !important;

      &#video0 {
        top: calc(10 * var(--faktor));
      }

      &#video1 {
        top: calc(30 * var(--faktor));
      }

      &#video2 {
        top: calc(50 * var(--faktor));
      }

      &#video3 {
        top: calc(70 * var(--faktor));
      }

      &#video4 {
        top: calc(90 * var(--faktor));
      }
    }

    #videoplayeroverlay {

      #playercontainer {

        #videoplayer {
          width: 90%;
          margin-left: 5%;
        }

        #closeicon {
          font-size: calc(6 * var(--faktor)) !important;
        }
      }
    }
  }
}
