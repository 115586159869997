/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@font-face {
  font-family: 'RockSalt';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/RockSalt.ttf');
}

@font-face {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Lato-Regular.ttf');
}

.rs {
  font-family: "RockSalt"
}

.sb {
  font-family: "Lato-Regular"
}

ion-header {
  height: 145px;

  .shadow{ 
      height: 65px;
      display: flex;
      
      color: #fff; 
      
      line-height: 45px;
      padding-top: 20px;

      .title{
        text-align: center;
        font-family: "RockSalt";
        font-size: 20px;
      }

      .s_punkte{
          padding-left: 20px;
          width: 120px;
      }

      .s_zeit{
          padding-right: 20px;
          width: 120px;
          text-align: right;
      }
  }
  ion-toolbar {
    --background: transparent;
    --min-height: 80px;
    color: #000;
    text-align: left;
    font-family: "RockSalt";

    .d_title{
        padding-left: 10vw;
        --min-height: 80px;
        line-height: 80px;
        height: 80px;
        color: #fff;
    }

    .trans{
        color: #fff;
        opacity: 0.65;
        font-size: 32px;
        padding-right: 10vw;
    }

    ion-title {
      font-size: 30px;
      line-height: 80px;
      height: 80px;
      text-align: left;
    }
  }

  &.wrapBgGruen {
    background: url(/assets/images/hg_streifen_gruen.jpg) no-repeat center center / cover;

    .trans{
        color: #000;
        opacity: 0.35;
    }

    .shadow{
        background: linear-gradient(to top, #7DB713 0%, black 50%);
    }
  }

  &.wrapBgLila {
    background: url(/assets/images/hg_streifen_lila.jpg) no-repeat center center / cover;

    .shadow{
        background: linear-gradient(to top, #8D00B8 0%, black 50%);
    }    
  }

  &.wrapBgTuerkis {
    background: url(/assets/images/hg_streifen_tuerkis.jpg) no-repeat center center / cover;

    .shadow{
        background: linear-gradient(to top, #14B2B8 0%, black 50%);
    }
  }

  &.wrapBgOrange {
    background: url(/assets/images/hg_streifen_orange.jpg) no-repeat center center / cover;

    .shadow{
        background: linear-gradient(to top, #FF9300 0%, black 50%);
    }
  }

  &.wrapBgBlau {
    background: url(/assets/images/hg_streifen_blau.jpg) no-repeat center center / cover;

    .shadow{
        background: linear-gradient(to top, #1427B8 0%, black 50%);
    }
  }

  &.wrapBgRot {
    background: url(/assets/images/hg_streifen_rot.jpg) no-repeat center center / cover;

    .shadow{
        background: linear-gradient(to top, #B61E3F 0%, black 50%);
    }    
  }
}


ion-content.black {
  --background: transparent;
  background: url(/assets/images/hg_schwarz.jpg) no-repeat center center / cover;
  background-size: cover;
}

.timer {
  padding-right: 4vw;
  font-size: 3vw;
  font-family: "Lato-Regular";
  color: #fff;
}

.punkte {
  color: "#fff";
  margin-left: 2vw;
  font-size: 3vw;
  font-family: "Lato-Regular";
}

ion-header ion-toolbar:first-of-type {
  padding-top: 0;
}


.avatar_toolbar {
    height: 50px;
    border-radius: 60px;
    top: 15px;
    position: relative;
    margin-right: 20px;
}


// Header


// Desktop-Styles
@import "./theme/desktop.scss";